import * as React from 'react';

import Layout from '../components/Layout';
import NavigationBanner from '../components/NavigationBanner';
import './privacy.scss';

const PrivacyPage = () => (
  <Layout
    selectedMenuItem="home"
    banner={<NavigationBanner />}
    className="privacy"
  >
    <div className="row">
      <div className="col-lg-10 col-xl-8">
        <h1>
          Privacy Policy for
          <br />
          Sugarless iOS App
        </h1>
        <p>We don&apos;t track your behaviour and all of your data is stored locally.</p>
        <p>
          We use
          {' '}
          <a href="https://www.revenuecat.com/privacy">RevenueCat</a>
          {' '}
          to manage paid subscriptions and
          {' '}
          <a href="https://www.apple.com/legal/privacy/">Apple</a>
          {' '}
          as a payment provider. Both of them keep track of your purchase history,
          but we cannot link it to your identity.
        </p>
        <p>
          If you send us an email it will go into our inbox and we will keep it.
          You may contact us about this policy at
          {' '}
          <a href="mailto:privacy@sugarlessapp.com">privacy@sugarlessapp.com</a>
          .
        </p>
        <p>Sugarless is based in the EU.</p>
      </div>
    </div>
  </Layout>
);

export default PrivacyPage;
